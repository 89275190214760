/* istanbul ignore file */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Image } from '@chakra-ui/react'

import { Link } from '@tofu/shop/ui/atoms/link'
import { Text } from '@tofu/shared/ui/atoms/text'
import { Box } from '@tofu/shared/ui/atoms/box'
import { Flex } from '@tofu/shared/ui/atoms/flex'
import { VStack } from '@tofu/shared/ui/atoms/stack'
import { Button } from '@tofu/shared/ui/atoms/button'
import { trackClick } from '@tofu/shared/utils/analytics'

import star from './slice-hero-star.svg'
import { TSliceHero } from './slice-hero.types'

const breakpoints = {
  xlDesktop: 1600,
  largeDesktop: 1200,
  desktop: 992,
  tablet: 767,
  phablet: 480,
  phone: 320
}

export const SliceHero: TSliceHero = ({ promo_code }) => {
  const Badge = ({ ...styles }) => {
    return (
      <VStack
        height={104}
        width={104}
        spacing={1}
        backgroundImage={star}
        textAlign='center'
        transform='rotate(8deg)'
        justifyContent='center'
        color='white'
        {...styles}
      >
        <VStack spacing={0}>
          <Text
            fontSize='xl'
            lineHeight={1}
            fontWeight='bold'
            fontFamily='heading'
            textTransform='uppercase'
          >
            15% OFF
          </Text>
          <Text
            fontSize='lg'
            lineHeight={1}
            fontWeight='bold'
            fontFamily='heading'
            textTransform='uppercase'
          >
            on 3 ORDERS
          </Text>
        </VStack>
        <VStack spacing={0}>
          <Text
            as={Link}
            fontSize='xs'
            textDecoration='underline'
            href={`/shop?discount=${promo_code}
            `}
          >
            {promo_code}
          </Text>
        </VStack>
      </VStack>
    )
  }

  return (
    <Flex
      position='relative'
      height={{
        base: 540,
        sm: 650,
        md: 550,
        lg: 500,
        xl: 500
      }}
      overflow='hidden'
      bg='brandYellow'
    >
      <picture width='100%'>
        <source
          media='(min-width: 80em)' // xl
          srcset='/img/homepage/hero_xl@2x.jpg'
          fetchpriority='high'
        />
        <source
          width='100%'
          media='(min-width: 62em)' // lg
          srcset='/img/homepage/hero_lg@2x.jpg'
        />
        <source
          width='100%'
          media='(min-width: 48em)' // md
          srcset='/img/homepage/hero_md@2x.jpg'
        />

        <source
          media='(min-width: 30em)' // sm
          srcset='/img/homepage/hero_base@2x.jpg'
        />
        <Image
          fetchpriority='high'
          alt='woman eating allplants'
          src='/img/homepage/hero_base@2x.jpg'
          objectFit='cover'
          width={{
            base: '100vw',
            sm: '100vw',
            md: '100%',
            lg: '100%',
            xl: '100vw'
          }}
          height='100%'
          transform={{
            base: 'translateY(-20px)',
            sm: 'translateY(20px)',
            md: 'translateY(0)'
          }}
        />
      </picture>

      <Flex
        height='100%'
        width='100%'
        justifyContent={'flex-start'}
        maxWidth={breakpoints.largeDesktop}
        mx={'auto'}
        sx={{ position: 'absolute', top: 0, left: 0, right: 0 }}
      >
        <Flex
          margin={{
            base: '0 auto',
            md: '0'
          }}
          width='100%'
          justifyContent='flex-end'
          alignItems='center'
        >
          <VStack
            py={[3, 6, 6, 12]}
            mx={['auto', 'auto', 0, 0]}
            px={[0, 0, 6, 6]}
            spacing={[0, 0, 10]}
            alignItems={['center', 'flex-start']}
            height='100%'
            justifyContent={['space-between', 'space-between', 'flex-start']}
          >
            <VStack
              color='black'
              spacing={1}
              justifyContent='center'
              alignItems={['center', 'center', 'baseline', 'baseline']}
            >
              <VStack
                as='h1'
                spacing={[-1, -4, -4, -4]}
                alignItems='center'
                justifyContent='center'
              >
                <Text
                  as='span'
                  p={0}
                  m={0}
                  textStyle='hero'
                  textTransform='uppercase'
                  fontSize={['12vw', '90px', '100px', '112px']}
                >
                  Eat your 5-a-day,
                </Text>
                <Text
                  as='span'
                  textStyle='hero-condensed'
                  p={0}
                  m={0}
                  fontSize={['16vw', '120px', '130px', '148px']}
                >
                  Everyday
                </Text>
              </VStack>
              <Box
                as='p'
                bottom='0'
                textStyle='body-lg'
                width={['90%', '90%', '500px', '600px']}
                textAlign={['center', 'center', 'left', 'left']}
              >
                Fill your freezer with 100% plant-based meals that heat up
                in&nbsp;minutes
              </Box>
            </VStack>

            <Box width='100%' textAlign={['center', 'left', 'left', 'left']}>
              <Button
                as={Link}
                onClick={() => trackClick('cta.hero', { location: 'SHOP NOW' })}
                href='/shop'
                size='lg'
                variant='solid'
                colorScheme='blackAlpha'
                width={['80%', '100%', 'auto']}
              >
                Shop now
              </Button>
            </Box>
          </VStack>
        </Flex>
        <Badge
          position='absolute'
          bottom={['auto', 'auto', 'auto', '60px']}
          right={['10px', '40px', '120px', '110px']}
          top={['200px', '340px', '320px', 'auto']}
        />
      </Flex>
    </Flex>
  )
}
